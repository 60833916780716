<template>
  <div class="download" :class="{ isMobile }">
    <el-row class="el-row">
      <el-col :span="12" :xs="24">
        <client-item
          title="聚客量化交易平台"
          config="WIN7(64位)及以上操作系统，2核4G"
          version="1.12.13"
          download-link="javascript:"
          document-catalogue="聚客量化交易平台"
        >
          <template #default>
            <p>
              聚客量化交易平台是聚客量化亲情打造的一款交互性极好的量化平台，平台支持策略编写，回测，股票、期货实盘或模拟，内置多款优量化策略和分析指标
            </p>
          </template>
        </client-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ClientItem from '@/views/download/components/ClientItem'

export default {
  name: 'index',
  components: {
    ClientItem
  }
}
</script>

<style lang="scss" scoped>
.download {
  text-align: center;

  .el-row {
    min-width: 800px;
    max-width: 1400px;
    margin: 0 auto;
  }
  .box {
    max-width: 1400px;
    margin: 0 auto;
  }
}

.isMobile {
  padding: 20px 0;
  .el-row {
    width: 100%;
    min-width: 100%;
  }

  .client-item {
    margin: 20px 20px 0;
  }
}
</style>
