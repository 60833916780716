<template>
  <el-card class="client-item" :class="{ isMobile }">
    <el-container>
      <el-header height="30px">
        <h3>{{ title }}</h3>
      </el-header>
      <el-main style="height: max-content; --el-main-padding: 20px 20px 0 20px">
        <slot></slot>
      </el-main>
      <el-footer height="60px">
        <div style="margin-bottom: 10px">
          <p>
            <el-icon>
              <platform color="#409eff" />
            </el-icon>
            配置要求：{{ config }}
          </p>
          <p>
            <el-icon>
              <info-filled color="#409eff" />
            </el-icon>
            版本信息：v{{ version }}
          </p>
        </div>
        <el-button type="primary" @click="to(downloadLink)">软件下载</el-button>
        <el-button
          @click="
            $router.push({
              path: '/document',
              query: { catalogue: documentCatalogue }
            })
          "
          >使用说明</el-button
        >
      </el-footer>
    </el-container>
  </el-card>
</template>

<script>
import { InfoFilled, Platform } from '@element-plus/icons-vue'

export default {
  name: 'ClientItem',
  components: {
    InfoFilled,
    Platform
  },
  props: {
    title: String,
    introduce: String,
    downloadLink: String,
    documentCatalogue: String,
    version: String,
    config: String
  },
  methods: {
    to(link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.client-item {
  max-width: 600px;
  height: 340px;
  margin: 40px;
  text-align: left;
}

.isMobile.client-item {
  height: 200px;

  .el-card {
    padding: 0;
  }
  .el-card__body {
    padding: 0 !important;

    h3 {
      font-size: 14px;
      margin: 0;
    }

    .el-header {
      padding: 0;
    }

    .el-main {
      font-size: 12px;
      padding: 0;

      p {
        margin: 0;
      }
    }

    .el-footer {
      padding: 0;
      font-size: 12px;
    }
  }
}

p {
  margin: 0;
}
</style>
